<template>
  <div class="box">
    <div class="search">
      <div class="search-top">
        <div v-for="(item,i) in toplist" :key="i">{{item.name}}（{{item.value}}）</div>
      </div>
      <el-form label-width="120px" class="search-form" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="配送员姓名" prop="name">
              <el-input
                v-model="queryInfo.condition.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配送员手机号" prop="phone">
              <el-input
                v-model="queryInfo.condition.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配送区域" prop="areaName">
              <el-input
                v-model="queryInfo.condition.areaName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="申请时间" prop="applicantTime">
              <el-date-picker v-model="queryInfo.applicantTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前状态" prop="status">
              <el-select class="input" v-model="queryInfo.condition.status" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end;margin-top: 8px;">
            <el-button type="primary" @click="search" icon="el-icon-search"
              >查询</el-button
            >
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <div class="add" style="text-align: left;">
        <el-button type="primary" @click="added" >新增配送员</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 440px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column
          prop="name"
          label="配送员姓名"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="配送员手机号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="areaName"
          label="配送区域"
          
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="当前状态"
          
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1">待审核</div>
            <div v-if="scope.row.status == 2">已通过</div>
            <div v-if="scope.row.status == 3">已拒绝</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="industryType"
          label="配送类型"
          
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="applicantTime"
          label="申请时间"
          width="150"
          align="center"
        >

        </el-table-column>
        <!-- <el-table-column
          prop="auditStatus"
          label="审核状态"
          
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.auditStatus==1">审核中</el-button>
            <el-button type="text" v-else-if="scope.row.auditStatus==2">审核拒绝</el-button>
            <el-button type="text" v-else>审核通过</el-button>
          </template>
        </el-table-column> -->
        <el-table-column
          prop=" "
          label="操作"
          width="150"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">编辑</el-button>
            <el-button type="text" style="color: red;"  @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryInfo.currPage"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
      activeName: 'second',
      drawer:false,
      toplist:[{
          name:'全部',
          value:''
      },{
          name:'待审核',
          value:''
      },{
          name:'已通过',
          value:''
      },{
          name:'已拒绝',
          value:''
      }],
      options: [
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "已通过",
        },
        {
          value: 3,
          label: "已拒绝",
        },
      ],
      tableData:[],
      queryInfo:{
        condition: {
          id:'',
          name:'',
          applicantTime:'',
          phone:'',
          areaName:'',
          industryType:'',
          status:''
        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10
       
      },
      total :0,
      showDialog:false,
      businessdetail:{},
      id:'',
  };
},
mounted() {
  this.getList ()
},
methods: {
  change(e){
    console.log(e);
  },
  added(){
      this.$router.push({path:'/riderdetail'})
  },
  //获取数据
  getList () {
    var that = this;
    that.$http.post("/rider/list", that.queryInfo).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.total = response.data.data.count;
        that.queryInfo.currPage=response.data.data.currPage
        that.tableData = response.data.data.data;
      }
    });
  },
  deta(id){
    this.id = id
    var that = this;
    this.$router.push({path:'/riderdetail',query:{
      id:id
    }})
  },
  del(id){
    this.id = id
    var that = this;
    
    that.$http.post("/business/queryBusinessDetail",{
      id:id
    }).then(function (response) {
      console.log(response.data.data);
      if (response.data.code == 200) {
        that.businessdetail = response.data.data
      }
    });
    
  },
  search(){
    this.queryInfo.currPage=1;
    this.getList()
  },
  reset(){
    this.queryInfo.condition = {
      id:'',
      name:'',
      applicantTime:'',
      phone:'',
      areaName:'',
      industryType:'',
      status:''
    }
    this.queryInfo.currPage=1;
    this.getList()
  },
  // 修改页数大小
  handleSizeChange (val) {
    this.queryInfo.pageSize = val;
    this.getList()
  },

  // 获取当前页面
  handleCurrentChange (val) {
    this.queryInfo.currPage = val;
    this.getList()
  },
}
};
</script>

<style lang="less" scoped>
.box {
  .search {
      background-color: #fff;
      box-sizing: border-box;
      padding: 15px 15px 0;
      .search-top {
          padding: 5px;
          background-color: rgba(30, 152, 215, 0.14);
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          color: #1c99ff;
          div {
              width: 150px;
              text-align: center;
          }
      }
      .search-form{
        padding-right: 50px;
      }
  }
  .tab {
      background-color: #fff;
      margin-top: 15px;
      padding: 15px
  }
}
/deep/ .el-switch__label * {
  font-size: 12px;
}
</style>
